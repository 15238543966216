/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


// run svg4everybody as soon as possible
svg4everybody();


(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function(){
        // JavaScript to be fired on all pages

        // prevent touch devices from triggering a click on the nav when it's hovered
        $('#account > a').on('touchstart', function(e){
          e.preventDefault();
          $(e.target).parent().addClass('active');
          return false;
        });

        /*
        // quote form and button...this is if we don't use optinmonster
        $('.quote-button').on('click',function(e){
          e.preventDefault();
          $('.quote-form').toggleClass('active');
          return false;
        });
        $('.quote-form .close-menu').on('click', function(){
          $('.quote-form').removeClass('active');
        });
        */


        // handle the burger menu
        $('button.navbar-toggle').on('click', function(){
          $('body').addClass('show-menu');
          return false;
        });
        $('#burger-menu .close-menu').on('click', function(){
          $('body').removeClass('show-menu');
        });


        // sticky subnav
        if($('.banner ul.dropdown-menu:visible')[0]){
          var menusticky = new Waypoint.Sticky({
            element: $('.banner ul.dropdown-menu:visible')[0]
          });
          var loginsticky = new Waypoint.Sticky({
            element: $('#account:visible')[0]
          });
        }

        // on first scroll event, show the "get a quote" button
        var show_quote_button = function(){
          $('.quote-button').addClass('show');
        };
        //open the quote form
        $('.quote-button').on('click',function(e){
          e.preventDefault();
          $('#quote-form-modal').find('.mc4wp-alert').remove();
          $('#quote-form-modal').modal('show');
          //trigger event opened
          ga('send', 'event', 'Quote Form', 'View','Opened Form using Quote button');
          return false;
        });


        //track the "get a quote" close button
        $('#quote-form-modal button.close').on('click',function(e){
          ga('send', 'event', 'Quote Form', 'Closed','Closed Form with "x" close button');
        });
        $('#quote-form-modal .modal-footer .btn-link').on('click',function(e){
          ga('send', 'event', 'Quote Form', 'Closed','Closed Form with close link');
        });



        // define quote form
        var $quoteform = $('#quote-form-modal').find('form');
        // quote form submission
        $('#quote-form-modal button.submit').on('click',function(e){
          e.preventDefault();
          $('#quote-form-modal').find('form').trigger('submit');
          return false;
        });

        $('#quote-form-modal input[type=checkbox]').on('click',function(){
          $('#'+$(this).val()).toggleClass('form-slidedown');
        });

        //track the quote form
        jQuery(document).on('subscribe.mc4wp','.mc4wp-form', function() {
        	// analytics.js
        	ga('send', 'event', 'Quote Form', 'Submit','Submitted Form');
        });

        mc4wp.forms.on('subscribed', function(form) {
        	$('.mc4wp-form-fields .show-group').addClass('hide-group');
        });

        $('input[name=AVGBILL]').on('change',function(){
          if(parseInt($(this).val())>475){
            $(this).attr('step',50);
          }
          if(parseInt($(this).val())>=100){
            $(this).attr('step',50);
          }
        });

        $('input[type=number]').on('keypress',function(){
          console.log('pressed');
          return event.charCode >= 48 && event.charCode <= 57;
        });

        $(window).on('scroll', show_quote_button);

/*
        // define quote form
        var $quoteform = $('#quote-form-modal').find('form');
        // quote form submission
        $('#quote-form-modal button.submit').on('click',function(e){
          e.preventDefault();
          var jqxhr = $.ajax( {
            url: document.location.href,
            type: 'post',
            data: $quoteform.serialize()
          })
            .done(function(data) {
              console.log(data);
              //alert( "second success" );
              $alert = $(data).find('.mc4wp-alert');
              console.log($alert);
              if($alert.length > 0){
                console.log('show the alert',$alert);
                $quoteform.find('.mc4wp-alert').remove();
                $quoteform.prepend($alert);
              }
            })
            .fail(function() {

            });
          return false;
        });

*/

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page

        // grab the big number
        var $wholeNumber = parseFloat($('.total .whole'));

        // start with zero
        var quoteTotal = 0;

        // listen for clicks on all 3 buttons
        $('.calculator > li:not(.total) button').on('click', function(e){
          e.preventDefault();
          $self = $(this);
          $slideDown = $self.parents('.slideDown');
          $button = $slideDown.siblings('.step1');

          // make sure it's always a number when we start
          quoteTotal = parseFloat(quoteTotal);

          // if we're active, add. otherwise, subtract
          if($self.hasClass('active')){
            quoteTotal -= parseFloat($self.attr('data-price'));
          }else{
            quoteTotal += parseFloat($self.attr('data-price'));
          }

          // deal with decimals
          quoteTotal = quoteTotal.toFixed(2);

          // turn our button on
          $self.toggleClass('active');
          // make sure the calculator is open in case the user started with a sm calculator
          $slideDown.addClass('active');
          $button.find('button').addClass('fadeOut');

          // if we have a number, format it
          if(quoteTotal > 0){

            // turn it into a string so we can split on decimal, and add 00 if we don't have a decimal
            var parts = (quoteTotal+'').split('.');
            if(parts.length === 1){
              parts[1] = '00';
            }

            // update dom
            $('.total .plan-price').addClass('active').find('.quote .whole').empty().append(parts[0]);
            $('.total .plan-price').addClass('active').find('.quote .decimal').empty().append(parts[1]);
          }else{
            $('.total .plan-price').removeClass('active').find('.quote .whole').empty().append('00');
          }

        });
        $('.get-quote').on('click',function(e){
          e.preventDefault();
          $(this).addClass('fadeOut');
          $('.quoter .slideDown').addClass('active');
        });

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
